import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getAuth } from 'firebase/auth';
import { useState } from 'react';
import { useAppSelector } from '../hooks';
import { getLiveMode } from '../store/reducers/user';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? 'https://staging.api.crypwave.com';

const useAPI = () => {
	const [loading, setLoading] = useState(false);
	const isLiveMode = useAppSelector(getLiveMode);
	const baseURL = `${API_BASE_URL}${isLiveMode ? '' : '/test'}`;

	const fetch = async (path: string, options?: AxiosRequestConfig) => {
		setLoading(true);
		const accessToken = await getAuth().currentUser?.getIdToken();
		try {
			const { data } = await axios({
				url: `${baseURL}/api/${path.replace(/^\/+/, '')}`,
				headers: {
					'Authorization': `Bearer ${accessToken!}`,
					'Content-Type': 'application/json',
					...options?.headers
				},
				method: options?.method,
				data: options?.data
			});

			return data;
		} catch (err) {
			throw Error((err as AxiosError).response?.data?.message ?? (err as AxiosError).message);
		} finally {
			setLoading(false);
		}
	};

	const checkAuth = async (accessToken: string) => {
		return fetch(`/users/auth-check?${document.cookie.split('utm_tracking=?')[1]?.split(';')[0]}`, {
			method: 'POST',
			data: { token: accessToken }
		});
	};

	const authState = async (accessToken: string) => {
		return fetch('/users/auth-state', {
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		});
	};

	const uploadFile = async (type: string, name: string, base64: string) => {
		return fetch('/upload', {
			method: 'POST',
			data: {
				base64,
				type,
				name
			}
		});
	};

	const uploadCompressed = async (type: string, name: string, base64: string) => {
		return fetch('/upload-compressed', {
			method: 'POST',
			data: {
				base64,
				type,
				name
			}
		});
	};

	const getMasterWallet = async () => {
		return fetch('/users/master-wallet');
	};

	const createVerificationSession = async () => {
		return fetch('/users/create-verification-session', { method: 'POST' });
	};

	const getBalance = async (coin: string) => {
		return fetch(`/users/balances/${coin}`);
	};

	const createKey = async (values: { name: string; allowedOrigins?: string }) => {
		return fetch('/users/keys', {
			method: 'POST',
			data: { ...values, allowedOrigins: values.allowedOrigins?.split(',') ?? [] }
		});
	};

	const getKeys = async () => {
		return fetch('/users/keys', {
			method: 'GET'
		});
	};

	const deleteKey = async (id: string) => {
		return fetch(`/users/keys/${id}`, {
			method: 'DELETE'
		});
	};

	const getCoins = async () => {
		return fetch('/coins', {
			method: 'GET'
		});
	};

	const toggleCoins = async (id: string, isActive: boolean) => {
		return fetch(`/coins/${id}`, {
			method: 'PUT',
			data: { isActive }
		});
	};

	const getWallets = async () => {
		return fetch('/wallets', {
			method: 'GET'
		});
	};

	const toggleWallets = async (id: string, isActive: boolean) => {
		return fetch(`/wallets/${id}`, {
			method: 'PUT',
			data: { isActive }
		});
	};

	const getSettlements = async () => {
		return fetch('/settlements');
	};

	const estimateGas = async (coin: string, walletAddress: string, amount: string | number) => {
		return fetch(`/settlements/estimate-gas/${coin}`, {
			method: 'POST',
			data: {
				walletAddress,
				amount
			}
		});
	};

	const settleAmount = async (coin: string, values: { walletAddress: string; walletName?: string; amount: number }) => {
		return fetch(`/settlements/${coin}`, {
			method: 'POST',
			data: values
		});
	};

	const getTransactions = async () => {
		return fetch('/transactions', {
			method: 'GET'
		});
	};

	const getStats = async (unit: string) => {
		return fetch(`/transactions/stats?unit=${unit}`, {
			method: 'GET'
		});
	};

	const getWalletGraph = async (unit: string) => {
		return fetch(`/transactions/wallets?unit=${unit}`, {
			method: 'GET'
		});
	};

	const getSalesGraph = async (unit: string) => {
		return fetch(`/transactions/sales?unit=${unit}`, {
			method: 'GET'
		});
	};

	const updateBusiness = async (data: unknown) => {
		return fetch('/users/business', {
			method: 'PUT',
			data
		});
	};

	const getBusiness = async () => {
		return fetch('/users/business', { method: 'GET' });
	};

	const updateKyc = async (data: unknown) => {
		return fetch('/users/kyc', {
			method: 'PUT',
			data
		});
	};

	const updateWebhook = async (data: unknown) => {
		return fetch('/users/webhooks', {
			method: 'PUT',
			data
		});
	};

	const getWebhook = async () => {
		return fetch('/users/webhooks', { method: 'GET' });
	};

	const getPaymentLinks = async () => {
		return fetch('/payment-links', {
			method: 'GET'
		});
	};

	const getPaymentLink = async (id: string) => {
		return fetch(`/payment-links/${id}`, {
			method: 'GET'
		});
	};

	const createPaymentLink = async (data: unknown) => {
		return fetch('/payment-links', {
			method: 'POST',
			data
		});
	};

	const getNotifications = async (page: number) => {
		return fetch(`/notifications?page=${page}`);
	};

	const updateBinanceEmail = async (data: any) => {
		return fetch('/users/binance-email', {
			method: 'PUT',
			data
		});
	};

	const getUnreadNotificationCount = async () => {
		return fetch('/notifications/unread-count');
	};

	const markNotificationAsRead = async (ids: string[]) => {
		return fetch('/notifications/mark-as-read', {
			method: 'PUT',
			data: { ids }
		});
	};

	const markAllNotificationsAsRead = async () => {
		return fetch('/notifications/mark-all-as-read', {
			method: 'PUT'
		});
	};

	const getTeamMembers = async () => {
		return fetch(`/team`, {
			method: 'GET'
		});
	};

	const createTeamMember = async (data: unknown) => {
		return fetch('/team', {
			method: 'POST',
			data
		});
	};

	const deleteTeamMember = async (id: string) => {
		return fetch(`/team/${id}`, {
			method: 'DELETE'
		});
	};

	const updateTeamMember = async (id: string, data: unknown) => {
		return fetch(`/team/${id}`, {
			method: 'PUT',
			data
		});
	};

	return {
		loading,
		checkAuth,
		authState,
		getMasterWallet,
		createVerificationSession,
		createKey,
		getKeys,
		deleteKey,
		getCoins,
		toggleCoins,
		getWallets,
		toggleWallets,
		getSettlements,
		getBalance,
		getTransactions,
		getStats,
		getWalletGraph,
		getSalesGraph,
		updateBusiness,
		getBusiness,
		getWebhook,
		updateWebhook,
		getPaymentLinks,
		getPaymentLink,
		createPaymentLink,
		settleAmount,
		estimateGas,
		uploadFile,
		uploadCompressed,
		updateKyc,
		getNotifications,
		updateBinanceEmail,
		getUnreadNotificationCount,
		markAllNotificationsAsRead,
		markNotificationAsRead,
		getTeamMembers,
		createTeamMember,
		deleteTeamMember,
		updateTeamMember
	};
};

export default useAPI;
