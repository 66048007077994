import { Col, Row, Spin } from 'antd';
import React from 'react';

// import Logo from '../assets/images/logo.png';

const Loader = () => {
	return (
		<Row style={{ height: '100vh' }} align="middle" justify="center">
			<Col
				style={{
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					gap: '2rem',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<img src="/images/logo/favicon.svg" alt="img" width="200px" height="200px" style={{ display: 'block', borderRadius: '50%' }} />
				<Spin size="large" />
			</Col>
		</Row>
	);
};

export default Loader;
