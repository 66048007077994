/* eslint-disable @typescript-eslint/no-unused-vars */
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import KYCForm from '../components/KYCForm';
import { useAppSelector } from '../hooks';
import useAPI from '../hooks/useAPI';
import { getUser } from '../store/reducers/user';
import { Company } from '../types';

const KYCDone = () => {
	const [company, setCompany] = React.useState<Company | null>(null);
	const { getBusiness } = useAPI();
	const user = useAppSelector(getUser);
	const navigate = useNavigate();

	const getBrand = async () => {
		try {
			const res = await getBusiness();
			setCompany(res);
		} catch {}
	};

	React.useEffect(() => {
		getBrand();
	}, []); // eslint-disable-line

	React.useEffect(() => {
		if (user?.isVerified) navigate('/dashboard');
	}, [user, navigate]);

	return (
		<Row gutter={[24, 0]}>
			<Col span={24} md={24} className="mb-24">
				<Card
					title={
						<div>
							<h6 className="font-bold">KYC Details</h6>
						</div>
					}
					extra={
						<>
							<Button type="dashed" icon={<PlusOutlined />} onClick={() => null}>
								<span>Complete KYC</span>
							</Button>
						</>
					}
					bodyStyle={{}}
					style={{ alignItems: 'center' }}
				>
					{company && <KYCForm company={company} />}
				</Card>
			</Col>
		</Row>
	);
};

export default KYCDone;
