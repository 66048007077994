import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

const initialState: Record<string, number> = {
	MATIC: 0,
	BNB: 0,
	ETH: 0
};

export const coinSlice = createSlice({
	name: 'coin',
	initialState,
	reducers: {
		setBalance: (state, action: PayloadAction<{ coin: string; balance: number }>) => {
			state[action.payload.coin] = action.payload.balance;
		}
	}
});

export const { setBalance } = coinSlice.actions;

export const getBalances = (state: RootState) => state.coin ?? 0; // eslint-disable-line

export default coinSlice.reducer;
