import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface User {
	displayName: string;
	email: string;
	avatarURL: string;
	uid: string;
	isVerified?: boolean;
	firstName?: string;
	lastName?: string;
	country?: string;
	city?: string;
	postalCode?: string;
}

interface MasterWalletAddress {
	wallets: Record<string, string>;
	binancePayEmail?: string;
}

export interface UserState {
	accessToken: string | null;
	user: User | null;
	wallet: MasterWalletAddress | null;
	isLiveMode: boolean;
}

const initialState: UserState = {
	accessToken: null,
	user: null,
	wallet: null,
	isLiveMode: localStorage.getItem('isLiveMode') === 'true'
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setAccessToken: (state, action: PayloadAction<string | null>) => {
			state.accessToken = action.payload;
		},
		setUser: (state, action: PayloadAction<User | null>) => {
			state.user = action.payload;
		},
		setLiveMode: (state, action: PayloadAction<boolean>) => {
			localStorage.setItem('isLiveMode', action.payload.toString());
			state.isLiveMode = action.payload;
		},
		setMasterWallet: (state, action: PayloadAction<MasterWalletAddress | null>) => {
			state.wallet = action.payload;
		},
		setKYCStatus: (state, action: PayloadAction<boolean>) => {
			if (state.user) {
				state.user.isVerified = action.payload;
			}
		}
	}
});

export const { setAccessToken, setUser, setLiveMode, setMasterWallet, setKYCStatus } = userSlice.actions;

export const getUser = (state: RootState) => state.user.user;
export const getLiveMode = (state: RootState) => state.user.isLiveMode;
export const getWallet = (state: RootState) => state.user.wallet;

export default userSlice.reducer;
