import React, { lazy, Suspense } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import 'antd/dist/antd.min.css';
import './assets/styles/main.css';
import './assets/styles/responsive.css';
import { useAppDispatch, useAppSelector } from './hooks';
import { getUser, setAccessToken, setLiveMode, setUser } from './store/reducers/user';
import useAPI from './hooks/useAPI';
import Loader from './components/Loader';
import KYCDone from './pages/KYC';

const Main = lazy(() => import('./components/layout/Main'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Profile = lazy(() => import('./pages/Profile'));
const Settings = lazy(() => import('./pages/Settings'));
const SignIn = lazy(() => import('./pages/SignIn'));
const Transactions = lazy(() => import('./pages/Transactions'));
const Settlements = lazy(() => import('./pages/Settlements'));
const PaymentLinks = lazy(() => import('./pages/PaymentLinks'));
const Team = lazy(() => import('./pages/Team'));

const app = initializeApp({
	apiKey: 'AIzaSyCxwu5AhAqY-wnzDYLY9sdXd9BIxx1zucE',
	authDomain: 'crypwaveofficial.firebaseapp.com',
	projectId: 'crypwaveofficial',
	storageBucket: 'crypwaveofficial.appspot.com',
	messagingSenderId: '277178036201',
	appId: '1:277178036201:web:b221309c277aa753486578',
	measurementId: 'G-NEZJSKTFSG'
});
const auth = getAuth(app);

const Protected: React.FC = ({ children }) => {
	const [loading, setLoading] = React.useState(true);
	const user = useAppSelector(getUser);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { authState } = useAPI();

	React.useEffect(() => {
		onAuthStateChanged(auth, async (user) => {
			if (user) {
				dispatch(
					setUser({
						displayName: user.displayName!,
						email: user.email!,
						uid: user.uid,
						avatarURL: user.photoURL!
					})
				);

				try {
					// @ts-expect-error
					window.heap?.identify?.(user.email);
				} catch {}

				const accessToken = await user.getIdToken();
				try {
					const res = await authState(accessToken);
					if (res && !res.isVerified) dispatch(setLiveMode(false));
					dispatch(setUser(res));
					dispatch(setAccessToken(accessToken));
				} catch {}
			} else {
				dispatch(setUser(null));
				dispatch(setAccessToken(null));
			}
			setLoading(false);
		});
	}, [dispatch]); // eslint-disable-line

	React.useEffect(() => {
		if (!user && !loading) {
			navigate('/sign-in');
		}
	}, [loading, navigate, user]);

	return user && !loading ? <Main>{children}</Main> : <Loader />;
};

message.config({ maxCount: 1 });

const App = () => {
	return (
		<Suspense fallback={<Loader />}>
			<Routes>
				<Route path="/sign-in" element={<SignIn />} />
				<Route path="/sign-up" element={<SignIn />} />

				<Route
					path="/dashboard"
					element={
						<Protected>
							<Dashboard />
						</Protected>
					}
				/>
				<Route
					path="/transactions"
					element={
						<Protected>
							<Transactions />
						</Protected>
					}
				/>
				<Route
					path="/settlements"
					element={
						<Protected>
							<Settlements />
						</Protected>
					}
				/>
				<Route
					path="/settings"
					element={
						<Protected>
							<Settings />
						</Protected>
					}
				/>
				<Route
					path="/team"
					element={
						<Protected>
							<Team />
						</Protected>
					}
				/>
				<Route
					path="/profile"
					element={
						<Protected>
							<Profile />
						</Protected>
					}
				/>

				<Route
					path="/kyc"
					element={
						<Protected>
							<KYCDone />
						</Protected>
					}
				/>

				<Route
					path="/payment-links"
					element={
						<Protected>
							<PaymentLinks />
						</Protected>
					}
				/>
				<Route
					path="*"
					element={
						<Protected>
							<Dashboard />
						</Protected>
					}
				/>
			</Routes>
		</Suspense>
	);
};

export default App;
