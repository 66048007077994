import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Steps, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React from 'react';
import { useAppDispatch } from '../hooks';
import useAPI from '../hooks/useAPI';
import { setKYCStatus } from '../store/reducers/user';
import { Company } from '../types';

const getBase64 = (file: RcFile): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});
};

const KYCForm: React.FC<{ company: Company | null }> = ({ company }) => {
	const [current, setCurrent] = React.useState(0);
	const [attachments, setAttachments] = React.useState<{ [key: string]: string }>({});
	const { uploadFile, updateKyc } = useAPI();
	const [uploading, setUploading] = React.useState(false);
	const dispatch = useAppDispatch();

	const steps = [
		{
			title: 'Business Details',
			content: (
				<>
					<Row gutter={[24, 0]}>
						<Col span={24} md={12}>
							<Form.Item
								className="username"
								label="Business Type"
								name={['business', 'type']}
								initialValue={company?.business?.type}
								rules={[{ required: true, message: 'This field is required.' }]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								className="username"
								label="Business Name"
								name={['business', 'name']}
								initialValue={company?.business?.name}
								rules={[{ required: true, message: 'This field is required.' }]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								className="username"
								label="Industry"
								name={['business', 'industry']}
								initialValue={company?.business?.industry}
								rules={[{ required: true, message: 'This field is required.' }]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								className="username"
								rules={[
									{
										required: true,
										message: 'This field is required.'
									}
								]}
								label="Website"
								name={['business', 'website']}
								initialValue={company?.business?.website}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								className="username"
								rules={[
									{
										required: true,
										message: 'This field is required.'
									}
								]}
								label="Privacy URL"
								name={['business', 'privacy']}
								initialValue={company?.business?.privacy}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								className="username"
								rules={[
									{
										required: true,
										message: 'This field is required.'
									}
								]}
								label="Terms URL"
								name={['business', 'terms']}
								initialValue={company?.business?.terms}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								className="username"
								label="Company Registration Document"
								valuePropName="file"
								name={['files', 'registration']}
								rules={[
									{
										required: true,
										message: 'This field is required.'
									}
								]}
							>
								<Upload
									accept=".png,.jpg,.jpeg,.gif"
									beforeUpload={(file) => {
										uploadIdProof(file, 'registration');
										return false;
									}}
								>
									<Button loading={uploading} icon={<UploadOutlined />}>
										Click to Upload
									</Button>
								</Upload>
							</Form.Item>
						</Col>
					</Row>
				</>
			)
		},
		{
			title: 'Business Address',
			content: (
				<>
					<Row gutter={[24, 0]}>
						<Col span={24} md={12}>
							<Form.Item
								className="username"
								label="Country"
								name={['address', 'country']}
								initialValue={company?.address?.country}
								rules={[{ required: true, message: 'This field is required.' }]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								className="username"
								label="City"
								name={['address', 'city']}
								initialValue={company?.address?.city}
								rules={[{ required: true, message: 'This field is required.' }]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								className="username"
								label="Zip Code/Postal Code"
								name={['address', 'zipCode']}
								initialValue={company?.address?.zipCode}
								rules={[{ required: true, message: 'This field is required.' }]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								className="username"
								label="State/Province"
								name={['address', 'state']}
								initialValue={company?.address?.state}
								rules={[{ required: true, message: 'This field is required.' }]}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</>
			)
		},
		{
			title: 'Director Details',
			content: (
				<>
					<Row gutter={[24, 0]}>
						<Col span={24} md={12}>
							<Form.Item
								className="username"
								label="Director Name"
								name={['director', 'name']}
								initialValue={company?.director?.name}
								rules={[{ required: true, message: 'This field is required.' }]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								className="username"
								label="Director Email"
								name={['director', 'email']}
								initialValue={company?.director?.email}
								rules={[{ required: true, message: 'This field is required.' }]}
							>
								<Input />
							</Form.Item>
						</Col>

						<Col span={24} md={12}>
							<Form.Item
								className="username"
								label="Director Mobile"
								initialValue={company?.director?.mobile}
								name={['director', 'mobile']}
							>
								<Input />
							</Form.Item>
						</Col>
					</Row>
				</>
			)
		},
		{
			title: 'ID Proof',
			content: (
				<Row gutter={[24, 0]}>
					<Col span={24} md={12}>
						<Form.Item
							className="username"
							label="Type of Document"
							name={['idProofType']}
							initialValue={company?.idProofType}
							rules={[{ required: true, message: 'This field is required.' }]}
						>
							<Input />
						</Form.Item>

						<Row justify="space-between">
							<Form.Item
								className="username"
								label="Front Side"
								name={['files', 'idProofFront']}
								rules={[
									{
										required: true,
										message: 'This field is required.'
									}
								]}
								valuePropName="file"
							>
								<Upload
									accept=".png,.jpg,.jpeg,.gif"
									beforeUpload={(file) => {
										uploadIdProof(file, 'idProofFront');
										return false;
									}}
								>
									<Button loading={uploading} icon={<UploadOutlined />}>
										Click to Upload
									</Button>
								</Upload>
							</Form.Item>

							<Form.Item
								className="username"
								label="Back Side"
								valuePropName="file"
								name={['files', 'idProofBack']}
								rules={[
									{
										required: true,
										message: 'This field is required.'
									}
								]}
							>
								<Upload
									accept=".png,.jpg,.jpeg,.gif"
									beforeUpload={(file) => {
										uploadIdProof(file, 'idProofBack');
										return false;
									}}
								>
									<Button loading={uploading} icon={<UploadOutlined />}>
										Click to Upload
									</Button>
								</Upload>
							</Form.Item>
						</Row>
					</Col>
				</Row>
			)
		}
	];

	const uploadIdProof = async (file: RcFile, key: string) => {
		setUploading(true);
		try {
			const base64 = await getBase64(file);
			const { url } = await uploadFile(file.type, file.name, base64);
			setAttachments((state) => ({ ...state, [key]: url }));
			message.success('File uploaded successfully!');
		} catch {
			message.error('Failed to upload the file!');
		}
		setUploading(false);
	};

	const updateKycDetails = async (values: any) => {
		try {
			await updateKyc({ ...values, files: null, ...attachments });
			if (Object.keys(attachments).length === 3) dispatch(setKYCStatus(true));
			message.success('KYC details updated successfully!');
		} catch {
			message.error('Failed to update kyc details...');
		}
	};

	return (
		<>
			<Steps current={current}>
				{steps.map((item) => (
					<Steps.Step key={item.title} title={item.title} />
				))}
			</Steps>
			<Form
				onFinish={(values) => {
					if (current !== steps.length - 1) setCurrent(current + 1);
					updateKycDetails(values);
				}}
			>
				<div className="steps-content">
					<div style={{ padding: '50px' }}>{steps[current].content}</div>
				</div>
				<div className="steps-action">
					{current > 0 && (
						<Button style={{ margin: '0 8px' }} onClick={() => setCurrent(current - 1)}>
							Previous
						</Button>
					)}
					{current < steps.length - 1 && (
						<Button loading={uploading} type="primary" htmlType="submit">
							Save
						</Button>
					)}
					{current === steps.length - 1 && (
						<Button loading={uploading} type="primary" htmlType="submit">
							Done
						</Button>
					)}
				</div>
			</Form>
		</>
	);
};

export default KYCForm;
